import Vuex from 'vuex'
import actions from './actions'
import mutations from './mutations'
import config from '../config'
import { getStore } from '../utils/storage'

const createStore = () => {
  return new Vuex.Store({
    state: () => ({
      tokenName: config.tokenName,
      tokenInfo: '', // token信息
      temporaryUrl: '', // 临时url
      websiteInfo: null, // 站点信息
      userInfo: null,
      token: '',
      remember: false,
      id: '',
      domain: 'http://www.sxatis.org.cn',
      // 栏目分类配置
      itemcate: {
        'xhdt': 13,
        'zxhd': 20,
        'lnhd': 21,
        'gjjl': 22,
        'zjzk': 33,
        'hyfc': 23,
        'hzhb': 34,
        'tzgg': 14,
        'hydt': 12,
        'mhydt': 99,
      },
      currentLocale: 'zhObj', // 默认语言
      translations: {
        // 语言存储
        enObj: { },
        zhObj: { }
      } // 存储翻译内容
    }),
    mutations: mutations,
    actions: actions,
    getters: {
      enObj: state => state.enObj,
      zhObj: state => state.zhObj
    }
  })
}

export default createStore
