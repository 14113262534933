import { getUserInfo } from '../api/user.js'
import { navList, websiteInfo } from '~/api/main'
import cookie from "../utils/cookies";
import config from "../config";

export default {
  // 当请求打入时，最先调用的即是 nuxtServerInit 方法，可以通过这个方法预先将服务器的数据保存，如已登录的用户信息等。另外，这个方法中也可以执行异步操作，并等待数据解析后返回。
  async nuxtServerInit({ dispatch, commit, app  }, context) {

    console.log("contextcontextcontextcontext",app)
    const cookie = context.req ? context.req : context.cookies
    await dispatch('GET_NAV', cookie)
    await dispatch('GET_WEBSITE', cookie)
  },
  GET_NAV(store, cookie) { // 获取导航信息
    return new Promise((resolve, reject) => {
      navList(cookie).then(res => {
        store.state.navList = res
        console.log('服务初始化的导航数据', JSON.stringify(store.state.navList))
        store.commit('BUILD_NAV', res)
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  GET_WEBSITE(store, cookie) { // 获取网站信息`
    return new Promise((resolve, reject) => {
      websiteInfo( cookie ).then(res => {
        console.log("服务初始化的站点数据",res)
        store.state.websiteInfo = res.records
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  GET_USERINFO(store, cb) {
    // 获取用户信息
    getUserInfo().then(res => {
      console.log("this is user info res:", res)
      if (res) {
        store.commit('SET_USER', res)
        if (res.loginNum == 1) {
          this.$router.push(this.localePath('/account/profile'))
          this.$message.success('首次登录请修改信息')
        } else {
          this.$router.push(this.localePath('/'))
        }
        if (cb) {
          cb(store)
        }
      }
    }).catch(error => {
      console.error(error)
    })
  },
  REDIRECT_LOGIN(store, codeNo) {
    store.commit('SET_TEMPORARYURL')
    store.commit('SIGN_OUT')
    this.$router.push(this.localePath({ name: 'login' }))
  },
  /**
     * 缓存token
     * @param commit
     * @param token {String, {token: String, remember: String}}
     */
  setToken({ commit }, { token, remember, id }) {
    let rememberFlag = true;
    if (typeof token === 'object') {
      rememberFlag = token.remember;
      token = token.token;
    }
    commit('SET_TOKEN', { token: token, remember: rememberFlag, id: id });
  },
  async fetchTranslations(store, locale) {
    try {
      this.app.i18n.setLocaleMessage(locale, store.state.translations[locale]);
    } catch (error) {
      console.error('Failed to fetch translations:', error)
    }
  },
  async switchLocale({ dispatch, commit }, locale) {

    commit('SET_CURRENT_LOCALE', locale);
    await dispatch('fetchTranslations', locale);
  }
}
