import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1dc5ea7c = () => interopDefault(import('..\\pages\\account\\index.vue' /* webpackChunkName: "pages/account/index" */))
const _32aade28 = () => interopDefault(import('..\\pages\\childTab\\index.vue' /* webpackChunkName: "pages/childTab/index" */))
const _1f4438a5 = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages/index" */))
const _10a39a9b = () => interopDefault(import('..\\pages\\list.vue' /* webpackChunkName: "pages/list" */))
const _2e284888 = () => interopDefault(import('..\\pages\\login.vue' /* webpackChunkName: "pages/login" */))
const _0d68d3a0 = () => interopDefault(import('..\\pages\\register.vue' /* webpackChunkName: "pages/register" */))
const _6ff2b2bc = () => interopDefault(import('..\\pages\\reset.vue' /* webpackChunkName: "pages/reset" */))
const _7501b565 = () => interopDefault(import('..\\pages\\search.vue' /* webpackChunkName: "pages/search" */))
const _42bace8b = () => interopDefault(import('..\\pages\\survey\\index.vue' /* webpackChunkName: "pages/survey/index" */))
const _ac88f996 = () => interopDefault(import('..\\pages\\member\\list.vue' /* webpackChunkName: "pages/member/list" */))
const _472e9dec = () => interopDefault(import('..\\pages\\submenu\\_id\\index.vue' /* webpackChunkName: "pages/submenu/_id/index" */))
const _0353b5aa = () => interopDefault(import('..\\pages\\submenu\\_id\\subarticle\\_subId.vue' /* webpackChunkName: "pages/submenu/_id/subarticle/_subId" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _1dc5ea7c,
    name: "account___zhObj"
  }, {
    path: "/childTab",
    component: _32aade28,
    name: "childTab___zhObj"
  }, {
    path: "/enObj",
    component: _1f4438a5,
    name: "index___enObj"
  }, {
    path: "/list",
    component: _10a39a9b,
    name: "list___zhObj"
  }, {
    path: "/login",
    component: _2e284888,
    name: "login___zhObj"
  }, {
    path: "/register",
    component: _0d68d3a0,
    name: "register___zhObj"
  }, {
    path: "/register",
    component: _0d68d3a0,
    name: "register___zhObj"
  }, {
    path: "/reset",
    component: _6ff2b2bc,
    name: "reset___zhObj"
  }, {
    path: "/search",
    component: _7501b565,
    name: "search___zhObj"
  }, {
    path: "/survey",
    component: _42bace8b,
    name: "survey___zhObj"
  }, {
    path: "/enObj/account",
    component: _1dc5ea7c,
    name: "account___enObj"
  }, {
    path: "/enObj/childTab",
    component: _32aade28,
    name: "childTab___enObj"
  }, {
    path: "/enObj/list",
    component: _10a39a9b,
    name: "list___enObj"
  }, {
    path: "/enObj/login",
    component: _2e284888,
    name: "login___enObj"
  }, {
    path: "/enObj/register",
    component: _0d68d3a0,
    name: "register___enObj"
  }, {
    path: "/enObj/register",
    component: _0d68d3a0,
    name: "register___enObj"
  }, {
    path: "/enObj/reset",
    component: _6ff2b2bc,
    name: "reset___enObj"
  }, {
    path: "/enObj/search",
    component: _7501b565,
    name: "search___enObj"
  }, {
    path: "/enObj/survey",
    component: _42bace8b,
    name: "survey___enObj"
  }, {
    path: "/member/list",
    component: _ac88f996,
    name: "member-list___zhObj"
  }, {
    path: "/enObj/member/list",
    component: _ac88f996,
    name: "member-list___enObj"
  }, {
    path: "/",
    component: _1f4438a5,
    name: "index___zhObj"
  }, {
    path: "/enObj/submenu/:id",
    component: _472e9dec,
    name: "submenu-id___enObj"
  }, {
    path: "/enObj/submenu/:id?/subarticle/:subId?",
    component: _0353b5aa,
    name: "submenu-id-subarticle-subId___enObj"
  }, {
    path: "/submenu/:id",
    component: _472e9dec,
    name: "submenu-id___zhObj"
  }, {
    path: "/submenu/:id?/subarticle/:subId?",
    component: _0353b5aa,
    name: "submenu-id-subarticle-subId___zhObj"
  }, {
    path: "/enObj*",
    component: _1f4438a5,
    name: "custom___enObj"
  }, {
    path: "*",
    component: _1f4438a5,
    name: "custom___zhObj"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
