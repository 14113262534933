import cookie from '../utils/cookies'
import { getSession, getStore, removeStore, setSession, setStore } from '../utils/storage'
// import axios from '~/api/main.js'

export default {
  // 初始化站点
  INIT_WEB: (state) => {
    const token = cookie.getInClient(state.tokenName)
    console.log("tokentokentokentokentoken",token)
    if (token) {
      const userInfo = JSON.parse(getStore('OcUserInfo'))
      state.tokenInfo = token
      state.userInfo = userInfo
    } else {
      state.userInfo = ''
      removeStore('OcUserInfo')
      removeStore('tokenInfo')
    }
  },
  // 记录token
  SET_TOKEN: (state, token) => {
    // 此处必须加path，否则在某些浏览器无法通过js移除
    cookie.setInClient({ key: state.tokenName, val: token })
    state.tokenInfo = token
    setStore('tokenInfo', token)
  },
  // SET_TOKEN(state, { token, remember, id }) {
  //   state.token = token
  //   state.remember = remember
  //   // state.id = id // 将用户 id 存储在 state 中
  //   state.userInfo = id
  //   if (remember) {
  //     cookie.setInClient({ key: state.tokenName, val: token })
  //     state.tokenInfo = token
  //     setStore('storetoken', token)
  //     setStore('OcUserInfo', id)
  //     // localStorage.setItem('storetoken', token);
  //     // localStorage.setItem('storeid', id) // 将 id 存储在 localStorage 中
  //   } else {
  //     setSession('storetoken', token)
  //     setSession('OcUserInfo', id)
  //     // setSession('storeid', id)
  //     // sessionStorage.setItem('storetoken', token)
  //     // sessionStorage.setItem('OcUserInfo', id) // 将 id 存储在 sessionStorage 中
  //     // sessionStorage.setItem('storeid', id) // 将 id 存储在 sessionStorage 中
  //   }
  // },
  // 记录用户信息
  SET_USER: (state, data) => {
    state.tokenInfo = getStore('tokenInfo')
    state.userInfo = data
    setStore('OcUserInfo', data)
    console.log('this is userinfo:', state.userInfo)
  },
  // 记录当前url
  SET_TEMPORARYURL: (state, data) => {
    const uri = window.location.href
    setSession('temporaryUrl', uri)
  },
  // 获取临时url
  GET_TEMPORARYURL(state) {
    const uri = getSession('temporaryUrl')
    if (uri) {
      state.temporaryUrl = uri
    }
  },
  // 服务端获取token
  GET_TOKEN_SERVER: (state, req) => {
    const cook = cookie.getInServer(req)
    state.tokenInfo = cook[state.tokenName]
  },
  // 退出登录
  SIGN_OUT: (state) => {
    state.tokenInfo = ''
    state.userInfo = ''
    // 移除document.cookie
    document.cookie = state.tokenName + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/'
    cookie.delInClient(state.tokenName)
    removeStore('OcUserInfo')
    removeStore('tokenInfo')
  },
  SET_ITEMS: (state, { key, value }) => {
    state[key] = value
  },
  BUILD_NAV: (state, data) => {
    const itemObj = {}
    const zhObj = {
      link:'友情链接',
      contactWay:'联系方式',
      registerPage:{
        name:'姓 名',
        namePlaceholder:'请输入姓名',
        account:'用户名',
        accountText:'请输入用户名',
        phone:'联系电话',
        email:'邮箱',
        password2:'确认密码',
        password2Text:'请再次输入密码',
        register:'注 册',
        registerSuc:'注册成功',
        sucToLogin:'注册成功，请登录',
        registerFail:'注册失败，请重试',
        msg:'短信验证码',
        // 剩余共用其余模块内容
      },
      loginPage:{
        userName:'用户名',
        userNamePlaceholder:'请输入姓名',
        password:'密码',
        passwordPlaceholder:'请输入密码',
        code:'验证码',
        codePlaceholder:'请输入验证码',
        login:'登录',
        loging:'登录中...',
        userNameText:'请输入账号',
        codeText:'请输入验证码',
        sourceText:'请输入密码',
        loginSuc:'登录成功',
        find:'已有帐号，忘记密码？',
      },
      checkText:{
        sms:'请输入短信验证码',
        realname:'请输入真实姓名',
        nickname:'请输入用户名',
        gender:'请选择性别',
        mobile:'手机号格式不正确',
        email:'邮箱格式不正确',
        emailEmp:'邮箱不能为空',
        passwordNo:'两次输入的密码不一致',
        editSuss:'资料修改成功',
        editFail:'资料修改失败',
        downloadFail:'下载失败',
        templateNo:'未配置模板',
        noticeNo:'未配置须知',
        read:'请先阅读并同意入会须知',
        mobileEmp:'手机号码不能为空',
        mobileAvid:'手机号格式不正确',
      },
      beforeLogin:'请您先登录',
      toLogin:'前往登录',
      none:'暂无数据',
      user: {
          _name: '个人中心',
          profile: {
            _name: '资料修改',
            page:{
              baseInfo:'基本信息',
              name:'真实姓名:',
              namePlaceholder:'请输入真实姓名',
              nickName:'昵   称:',
              nickNamePlaceholder:'请输入昵称',
              sex:'性   别:',
              sexPlaceholder:'请选择性别',
              boy:'男',
              girl:'女',
              secrecy:'保密',
              account:'用户名:',
              accountText:'请输入用户名',
              mail:'邮  箱:',
              mailPlaceholder:'请输入邮箱',
              phone:'联系电话:',
              phonePlaceholder:'请输入联系电话',
              brief:'个人简介:',
              briefPlaceholder:'请输入个人简介',
              address:'街道地址:',
              addressPlaceholder:'请输入街道地址',
              ing:'保存中..',
              finsh:'保存更改'
            }
          },
          mail: {
            _name: '站内信',
            page:{
              application:'活动申请',
              applyText:'您申请报名的活动',
              review:'文章审核',
              reviewText:'您发布的文章信息',
              already:'已于',
              pass:'通过审核。',
              wait:'等待审核中',
              back:'被驳回。',
              reason:'原因'

            }
          },
          apply: {
            _name: '申请入会',
            page:{
              individualMember:'个人会员',
              nickname:'会员名称:',
              nickNamePlaceholder:'请输入会员名称',
              level:'会员等级:',
              levelPlaceholder:'请选择会员等级',
              template:'申请模板',
              download:'下载文件',
              apply:'入会申请:',
              applyText:'请上传入会申请书',
              upload:'上传',
              city:'所在城市:',
              cityPlaceholder:'请选择所在城市',
              tip:'阅读并同意入会须知事项',
              submit:'提交',
              corporateMembers:'企业会员',
              enterprise:'企业名称:',
              enterprisePlaceholder:'请输入企业名称',
              ing:'管理员审核中，请耐心等待',
              financialInfo:'资料已上传完毕，请等待工作人员联系。',
              notificationInfo: '通知信息...',
            },
          },

        },
      wzsy:'网站首页',
      lnhd:'历年活动',
      zxhd:'最新活动',
      zjzk:'专家智库',
      hyfc:'会员风采',
      xhdt:'协会动态',
      hzhb:'合作伙伴',
      gjjl:'国际交流',
      tzgg: '通知公告',
      hydt: '行业动态',
      hydt1: '会员动态',

    }
    const enObj = {
      link:'Friendly Link',
      contactWay:'Contact Information',
      registerPage:{
        name:'Name',
        namePlaceholder:'Please enter your name',
        account:'Account',
        accountText:'Please enter your account',
        phone:'Phone',
        email:'Email',
        password2:'Confirm password',
        password2Text:'Please enter your password again',
        register:'Register',
        registerSuc:'registered successfully',
        sucToLogin:'Registration successful, please log in',
        registerFail:'Registration failed, please try again',
        msg:'SmsCaptcha',
        // 剩余共用其余模块内容
      },
      loginPage:{
        userName:'Username',
        userNamePlaceholder:'Please enter your name',
        password:'Password',
        passwordPlaceholder:'Please input a password',
        code:'Verification Code',
        codePlaceholder:'Please enter the verification code',
        login:'Sign in',
        loging:'Logging in...',
        userNameText:'Please enter your account',
        codeText:'Please enter the verification code',
        sourceText:'Please select activity resources',
        loginSuc:'Login successful',
        find:'Already have an account, forgot password?',
      },
      checkText:{
        sms:'Please enter the SMS verification code',
        realname:'Please enter your real name',
        nickname:'enter one user name',
        gender:'Please select gender',
        mobile:'The format of the phone number is incorrect',
        email:'Email format incorrect',
        emailEmp:'Email cannot be empty',
        passwordNo:'The passwords entered twice are inconsistent',
        editSuss:'Data modification successful',
        editFail:'Data modification failed',
        downloadFail:'Download failed',
        templateNo:'Template not configured',
        noticeNo:'Notice of Unconfigured Configuration',
        read:'Please read and agree to the membership notice first',
        mobileEmp:'Mobile phone number cannot be empty',
        mobileAvid:'The format of the phone number is incorrect',
      },
      beforeLogin:'Please log in first',
      toLogin:'Go to login',
      none:'None',
      user: {
          _name: 'Person Center',
          profile: {
            _name: 'Profile Edit',
            page:{
              baseInfo:'Base Information',
              name:'Real name:',
              namePlaceholder:'Please enter your real name',
              nickName:'nickname:',
              nickNamePlaceholder:'Please enter a nickname',
              sex:'Gender:',
              sexPlaceholder:'Please select gender',
              boy:'male',
              girl:'female',
              secrecy:'secrecy',
              account:'Account:',
              accountText:'Please enter your account',
              mail:'Email :',
              mailPlaceholder:'Please enter your email address',
              phone:'phone:',
              phonePlaceholder:'Please enter your contact phone number',
              brief:'Personal Profile:',
              briefPlaceholder:'Please enter your personal profile',
              address:'Street address:',
              addressPlaceholder:'Please enter the street address',
              ing:'Saving..',
              finsh:'Save changes'
            }
          },
          mail: {
            _name: 'Mail',
            page:{
              application:'Activity application ',
              applyText:'The activity you applied for registration for ',
              review:'Article review ',
              reviewText:'The article information you posted ',
              already:' Already in ',
              pass:' Approved through review. ',
              wait:' Waiting for review ',
              back:' Rejected. ',
              reason:' reason '
            }
          },
          apply: {
            _name: ' Apply for membership',
            page:{
              individualMember:'Individual Member',
              nickname:'Member Name:',
              nickNamePlaceholder:'Please enter the member name',
              level:'Membership Level:',
              levelPlaceholder:'Please select membership level',
              template:'Application Template',
              download:'Download File',
              apply:'Membership Application:',
              applyText:'Please upload the membership application form',
              upload:'Upload',
              city:'Location City:',
              cityPlaceholder:'Please select the city where you are located',
              tip:'Read and agree to the membership notice',
              submit:'Submit',
              corporateMembers:'Corporate Members',
              enterprise:'Enterprise Name:',
              enterprisePlaceholder:'Please enter the company name',
              ing:'Administrator review in progress, please be patient and wait',
              financialInfo:'The information has been uploaded, please wait for the staff to contact.',
              notificationInfo: 'Notification Information...',
            },
          },
        },
      wzsy:'Home',
      lnhd:'PastActivities',
      zxhd:'LatestActivities',
      zjzk:'Expert Think Tank',
      hyfc:'MemberShowcase',
      xhdt:'AssociationNews',
      hzhb:'Partners',
      gjjl:'InternationalExchange',
      tzgg: 'Notice',
      hydt: 'IndustryTrends',
      hydt1: 'MemberNews',
    }
    // 构建栏目索引
    if (data && Array.isArray(data)) {
      data.forEach((item) => {
        const mainItem = item.mainColumn
        const sub = item.subColumn
        const key = mainItem['code']
        const val = mainItem.id
        // const enVal = mainItem?.nameEn
        // const zhVal = mainItem?.name
        itemObj[key] = val
        // zhObj[key] = zhVal
        // enObj[key] = enVal
        if (sub != null) {
          // sub = JSON.parse(sub)
          sub.forEach((childItem) => {
            itemObj[childItem['code']] = childItem['id']
            // zhObj[childItem['code']] = childItem['name']
            // enObj[childItem['code']] = childItem['nameEn']
            if (childItem.childrens != null) {
              childItem.childrens.forEach((item) => {
                itemObj[item['code']] = item['id']
                // zhObj[item['code']] = item['name']
                // enObj[item['code']] = item['nameEn']
              })
            }
          })
        }
      })
    }
    console.log('itemObj===>', itemObj)
    console.log('zhObj===>', zhObj)
    console.log('enObj===>', enObj)
    state.itemcate = itemObj
    state.translations.zhObj = {
      ...zhObj,
      'personCenter': '个人中心',
      'login': '登录',
      'register': '注册',
      'logout': '退出',
      'language': '语言',
      'more': '更多',
      'location': '当前位置'
    }
    state.translations.enObj = {
      ...enObj,
      'personCenter': 'Person Center',
      'login': 'login',
      'register': 'register',
      'logout': 'logout',
      'language': 'language',
      'more': 'more',
      'location': 'location',

    }
  },
  SET_CURRENT_LOCALE(state, locale) {
    setStore('currentLocale', locale)
    state.currentLocale = locale
  }
}
