export default function({ route, req, res, store, next }) {
  if (process.client) {
    store.commit('INIT_WEB')
    // // 证书重定向
    // const certificate = ['/submenu/wdzs', '/submenu/zscx']
    // // 判断路由跳转重定向
    // if (certificate.includes(route.path)) {
    //   redirect('/member/')
    // }
  }
  if (process.server) {
    store.commit('GET_TOKEN_SERVER', req)
  }
}
