import * as axios from 'axios'
import {Message} from 'element-ui'
import cookie from '../utils/cookies'
import config from '../config'
import {getStore} from "../utils/storage";

const createHttp = (token, options = {}, req) => {
  // let options = {}
  const head = {}
  console.log('options=============>', options)

  if (process.server) {

    if (token) {
      head.token = token
    }
    console.log('req=============>', req && req.headers ? req.headers : '')
    if (req) {
      head.Lang = cookie.getInServer(req)[config.lang] ? cookie.getInServer(req)[config.lang] : 'zhObj'
    }
    options.baseURL = config.baseUrl
  } else {
    options.baseURL = process.env.NODE_ENV === 'production' ? '' : '/api'
  }
  if (process.client) {
    // head.token = cookie.getInClient(config.tokenName)
    head.Authorization = cookie.getInClient(config.tokenName)
    head.Lang = cookie.getInClient(config.lang)
    if (req) {
      head.Lang = cookie.getInServer(req)[config.lang]
    }
  }

  options.headers = head
  const http = axios.create(options)
  http.interceptors.request.use(function(config) {
    console.log('requestconfig=====', config)
    return config
  }, function(error) {
    console.error(error)
    if (process.client) {
      return Promise.reject(error)
    }
  })

  http.interceptors.response.use(function(response) {
    const res = response.data
    if (res.type == 'text/xml') {
      return Promise.resolve(res)
    }
    if (res.code === 200 || res.code === 0) {
      return Promise.resolve(res.data)
    }
    if (response.data.code >= 300 && response.data.code <= 400) {
      if (process.client) { // 客户端请求接口token 过期让他重新登录
        if (window.location.href.indexOf('/login') === -1 && window.location.href.indexOf('/agreement') === -1) {
          // store.dispatch('REDIRECT_LOGIN')
          // return
        }
      }
    }
    if (res.code === 402) {
      Message.error(response.data.msg)
      return Promise.reject(response.data)
    }
    if (window.location.href.indexOf('/login') !== -1) {
      return Promise.resolve(response)
    }
    if (process.client) {
      try {
        const d = JSON.parse(response.config.data || response.config.params || {})
        if (d.isShowErrTip !== false) {
          // 过滤同时多个接口报token错误 会出现多个提示bug
          const title = localStorage.getItem('___errmsg')
          const time = localStorage.getItem('___errmsgTime')
          const newtime = (new Date()).getTime()
          if (title !== response.data.msg || (newtime - time) > 2000) {
            localStorage.setItem('___errmsg', response.data.msg)
            localStorage.setItem('___errmsgTime', newtime)
            Message.error(response.data.msg)
          }
          return Promise.reject(response.data)
        } else {
          return Promise.resolve(response.data)
        }
      } catch (error) {
        console.error(response.data)
        return Promise.resolve(response.data)
      }
    } else {
      return Promise.resolve(response.data)
    }
  }, function(error) {
    if (process.client) {
      return Promise.reject(error)
    } else {
      console.error(JSON.stringify(error))
      return Promise.resolve(error.response.data)
    }
  })
  return http
}
/**
 * 读取指定blob,返回转换为base64后的结果
 * @param blob
 * @returns {Promise<unknown>}
 */
function downloadExcel(response, fileName) {
  const blob = new Blob([response])
  var downloadElement = document.createElement('a')
  var href = window.URL.createObjectURL(blob)
  downloadElement.href = href
  downloadElement.download = fileName
  document.body.appendChild(downloadElement)
  downloadElement.click()
  document.body.removeChild(downloadElement)
  window.URL.revokeObjectURL(href)
}

export default createHttp
