// en_us.js
export default {
  toLogin: 'Go to login',
  beforeLogin: 'Please log in first',
  personalCenter: 'Personal Center',
  dataModification: 'Data Modification',
  mail: 'Mail ',
  applyJoin: 'Apply for membership'
  // 可以继续添加其他翻译
};
