import http from './method.js'

// 分类列表
export const categoryList = () => {
  return http().get('/course/api/category/list')
}
// 首页推荐课程
export const zoneList = (params = {}) => {
  return http().post('/course/api/zone/list', params)
}
// 站点信息
export const websiteInfo = (req) => {
  // return http().get('/system/api/sys/config/website')
  return http('', {}, req).get('/api/system/tyWbeSite/item/index')
}
// 首页导航栏
export const navList = (req) => {
  // return http().get('/system/api/website/nav/list')
  // eslint-disable-next-line no-return-assign
  return http('', {}, req).get('/api/system/tyWbeSite/getCate')
}
// 首页轮播图
// export const carouselList = () => {
//   return http().get('/system/api/website/carousel/list')
// }
export const carouselList = (req) => {
  return http('', {}, req).get('/api/system/tyWbeSite/getCarousel')
}

export const getArticles = (param, req) => {
  return http('', {}, req).get(`/api/system/tyWbeSite/getArticles/${param}`)
}

export const getArticlePage = (param, num) => {
  return http().get(`/api/system/tyWbeSite/getArticle/${param}/${num}`)
}

export const getArticleInfo = (cateId, articleId) => {
  return http().get(`/api/system/tyWbeSite/getArticleInfo/${cateId}/${articleId}`)
}
// 友情链接
export const linkList = () => {
  return http().get('/api/system/tyWbeSite/link/index')
}
// 邮件重置密码
export const resetPwdByemail = (email) => {
  return http().get('/api/system/email/send?to=' + email)
}
